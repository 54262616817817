.TableCenter td {
    text-align: center;
}

.center_{
    align-items: center;
}
.align-center{
    align-self: center;
}
.right{
    align-self: end;
    text-align: end;
    font-weight: bold;
}
